	/* Call Icon */
	$widget-default-color: #99ad3c;
	$widget-default-border: #b6ce48;
	$popup-background: #2b2b2c;
	$snow-white: #FFFFFF;
	$border-grey: #e0e0e0;
	.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=tel], .intl-tel-input.allow-dropdown input[type=text]{
		padding-left: 65px !important;
	}
	.popup-button {
		position: fixed;
		display: none;
		align-items: center;
		z-index: 1000;
		.twilio-popup {
			background-color: $popup-background;
			border-radius: 6px;
			font-size: 13px;
			color: $snow-white;
			margin-right: 10px;
			margin-left: 10px;
			padding: 6px;
		}
		.twilio-wd-open-widget-popup {
			width: 60px;
			height: 60px;
			background: $widget-default-color;
			cursor: pointer;
			border-radius: 50%;
			i {
				font-size: 32px;
				color: $snow-white;
				line-height: 60px;
				display: block;
				text-align: center;
			}
		}
	}
	li.country.preferred{
		display:none;
	}
	/* Popup style code */
	.lime-call-popup {
		user-select: none;
	font-size: 13px;
	/* Modal styles */
	.limecall-modal-body {
      padding: 25px 33px;
      padding-bottom: 0px;
      padding-top: 0;
      margin-top: 20px;
		}
	.limecall-modal-content {
		width: 350px;
		max-width: 100%;
		background-color: $snow-white;
		border-radius: 6px;
		padding: 6px 0px 5px;
		position: relative;
	}
  /* Close button style */
  .slidemodalClose{
    display: none;
  }
	.close {
			position: absolute;
			right: 5px;
			top: 5px;
			font-size: 10px;
			font-weight: 700;
		  z-index: 1201 !important;
			padding: 0;
			i {
				font-size: 15px;
				font-weight: 800;
			}
		}
	/* Nav bar styles */
		.nav {
			justify-content: center;
			flex-wrap: nowrap;
			.nav-item {
				flex-basis: 50%;
				text-align: center;
				background: #fff;
				border-bottom: 1px solid $border-grey;
				.borderedItem {
					&::after {
					content: "";
					display: block;
					position: absolute;
					top: 8px;
					right: 0px;
					bottom: 4px;
					background-color: $border-grey;
					height: 22px;
					width: 1px;
					}
				}
				.nav-link {
					border: none;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 11px;
          padding: 8px;
          // padding-bottom: 7px;
					position: relative;
					i {
						font-size: 13px;
						margin-right: 5px;
					}
					&:focus {
						border: none;
					}
				}
			}
		}
	}

	li.nav-item.active.borderedItem {
		background: #f8f8f8;
	}
	a.limecall-nav-link.nav-link {
		color: #000 !important;
	}

	.call_now__top {
		// display: flex;
		align-items: center;
		// margin-bottom: 10px;
		img {
			width: 55px;
			margin-right: 20px;
		}
		.title {
			color: #000;
			font-size: 26px;
			text-align: center;
			font-weight: 600;
			margin: 0;
		}
	}
	.tel-input {
		.intl-tel-input {
			width: 100%;
			.selected-flag {
				border-radius: .25rem 0px 0px 0.25rem;
				outline: 0;
				background-color: #f5f5f5;
				z-index: 900;
        padding: 0 0 0 15px !important;
        // width: 85px !important;
      }
		}
		input,select {
      font-size: 13px;
      font-family: inherit;
			outline: none !important;
			box-shadow: none !important;
			-webkit-transition: border 0.3s;
			transition: border 0.3s;
			background: transparent;
			border: 1px solid $border-grey;
			outline: none;
			width: 100%;
			height: 33px;
			padding-left: 70px;
		}
	}
	.modal {
		justify-content: center;
		align-items: center;
		background-color: rgba(56,56,56,.5)!important;
	}
	.call_widget__copyright {
		width: 400px;
		max-width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: absolute;
		bottom: -50px;
		left: 0;
		&:hover {
			text-decoration: none;
			outline: none;
		}
		&:focus {
			text-decoration: none;
			outline: none;
		}
		&:active {
			text-decoration: none;
			outline: none;
		}
		p {
			color: #03235a;
      font-size: 10px;
      letter-spacing: 0.5px;
      margin: 0 6px 0 0;
		}
		img {
			width: 80px;
		}
	}
	.schedule-later {
		font-size: 13px;
		color: #020022 !important;
		cursor: pointer;
		display: block;
		margin-top: 30px;
		text-align: center;
		font-weight: 700;
		letter-spacing: 0.1px;
  }
  .company_logo_header{
    width: unset !important;
    max-height: 35px;
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 5px;
  }
	.terms-apply {
    margin-bottom: 0.25rem !important;
		// position: absolute;
		// bottom: -3px;
		// right: 0;
		// left: 0;
		font-size: 11px;
		color: #828181 !important;
		text-align: center;
		font-weight: 500;
		letter-spacing: 0.1px;
		text-decoration: underline;
	}
	.button-group {
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $widget-default-border;
			color: $snow-white;
			width: 100%;
			background-color: $widget-default-border;
			height: 35px;
			font-size: 13px;
			font-weight: bold;
			letter-spacing: 1px;
			text-transform: uppercase;
			&:hover, &:active, &:focus {
				outline: none !important;
				transition: background-color 0.3s;
			}
		}
	}
	.timer-holder {
		width: 120px;
		margin: 0px auto 10px auto;
		text-align: center;
	}
	.display-timer-message {
		font-size: 18px;
		font-weight: 700;
		text-align: center;
		margin-top: 10px;
		letter-spacing: 0.8px;
	}
	.form-errors {
		color: red;
		margin-top: 5px;
		text-align: center;
	}
	.CircularProgressbar {
		width: 80%;
		margin: 0 auto;
	}
	.feedback {
		.feedbackInput {
			font-size: 13px;
		}
		.feedbackForm {
			display: flex;
			justify-content: center;
			.dv-star-rating {
				.dv-star-rating-star i {
					font-size: 40px;
				}
			}
		}
	}

	.formHolder {
    padding: 5px;
    margin-top: 0.3em;
    padding-top: 0.5em;
	.form__form-group {
		// margin-bottom: 10px;
	}
	.call_later__wrapper {
		display: flex;
		.schedule-call-select {
      border: 1px solid $border-grey;
      height: unset !important;
			padding: .6em 1.3em;
			width: 100%;
			min-height: 40px;
			font-weight: 400;
			text-align: left;
			outline: none;
			background-color: $snow-white;
			box-shadow: none;
			resize: none;
			color: #484848;
			border-radius: 5px;
			margin-bottom: 10px;
    }
    .schedule-call-select-mod {
			width: 100%;
			min-height: 33px;
			text-align: left;
			background-color: $snow-white;
			margin-bottom: 0.4rem;
		}
	}
  }
  .smartwidget-phonenumber-container{
    .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 .selected-flag{
      // width: 65px !important;
    }
    .call_later__wrapper {
      display: flex;
      .schedule-call-select {
        border: 1px solid $border-grey;
        padding: .6em 0.2em;
        width: 100%;
        min-height: 40px;
        font-weight: 400;
        text-align: left;
        outline: none;
        background-color: $snow-white;
        box-shadow: none;
        resize: none;
        color: #484848;
        border-radius: 5px;
        margin-bottom: 10px;
      }
    }
  }

	.message {
		textarea {
			height: 70px;
		}
		&__input {
			font-size: 13px;
		}
	}

	.reschedule-block {
		.reschedule-call {
			width: 100%;
			text-align: center;
			display: block;
			margin-top: 5px;
		}
	}

	.limecall-title {
    width: 100%;
		text-align: center !important;
		font-size: 21px !important;
		font-weight: 600 !important;
		line-height: 26px !important;
		text-decoration: none !important;
		text-transform: none !important;
		font-family:none;
		color: #000 !important;
	}
	.limecall-nav-link {
		margin: 0 !important;
	}
	.limecall-nav-item {
		min-width: 30%;
		max-width:33%
	}
	
	.nav-tabs > li > a {
		line-height: normal !important;
		text-transform: none !important; 
		letter-spacing: normal !important; 
		font-size: 12px !important; 
		font-weight: 500 !important;
	}
	.country-list{
		z-index: 101 !important;
	}

  /*! CSS imported from bootstrap */
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }
  [tabindex="-1"]:focus {
    outline: 0!important;
  }
  h3 {
    margin-top: 0;
    margin-bottom: .5rem;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .limecall-widget a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  .limecall-widget a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([tabindex]):focus,
  a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([tabindex]):focus {
    outline: 0;
  }
  img {
    vertical-align: middle;
    border-style: none;
  }
  button {
    border-radius: 0.25em !important;
    margin-bottom: 0.3rem !important;
  }
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  button,
  input,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  [type=button],
  button {
    -webkit-appearance: button;
  }
  [type=button]::-moz-focus-inner,
  button::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }
  h3 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }
  h3 {
    font-size: 1.75rem;
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(4.25rem);
    padding: .375rem .75rem;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #000;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none;
    }
  }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .form-control:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
  textarea.form-control {
    height: auto;
  }
  .form-group {
    margin-bottom: 0.4rem;
  }
  .limecall-modal-content > .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }
  .limecall-modal-content >.btn:hover {
    color: #212529;
    text-decoration: none;
  }
  .limecall-modal-content >.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .btn:disabled {
    opacity: .65;
  }
  .limecall-widget .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  .limecall-widget .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }
  .limecall-widget .btn-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  }
  .limecall-widget .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  .fade {
    transition: opacity .15s linear;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  .nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .nav-link {
    display: block;
    padding: .5rem 1rem;
  }
  .nav-link:focus,
  .nav-link:hover {
    text-decoration: none;
  }
  .nav-tabs {
    border-bottom: 2px solid #dee2e6;
  }
  .nav-tabs .nav-item {
    margin-bottom: -1px;
    margin-top: -3px;
  }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
  }
  .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  .tab-content > .tab-pane {
    display: none;
  }
  .tab-content > .active {
    display: block;
    position: absolute !important;
    top: 50% !important;
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
    width: 100%;
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 1.5;
  }
  .close:hover {
    color: #000;
    text-decoration: none;
  }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .limecall-modal {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    z-index: 1000000 !important;
    display: none;
    width: 100%;
    height: 100% !important;
    overflow: hidden;
    outline: 0;
    font-weight: normal;
    margin-top: unset !important;
    padding: unset !important;
    box-shadow: none !important;
  }
  .limecall-modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
  }
  .modal.fade .limecall-modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .modal.fade .limecall-modal-dialog {
      transition: none;
    }
  }
  .modal.show .limecall-modal-dialog {
    -webkit-transform: none;
    transform: none;
  }
  .limecall-modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: .3rem;
    outline: 0;
  }
  .limecall-modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
  }
  @media (min-width: 576px) {
    .limecall-modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
  }
  .position-relative {
    position: relative!important;
  }
  @media print {
    *,
    ::after,
    ::before {
      text-shadow: none!important;
      box-shadow: none!important;
    }
    a:not(.btn) {
      text-decoration: underline;
    }
    img {
      page-break-inside: avoid;
    }
    h3,
    p {
      orphans: 3;
      widows: 3;
    }
    h3 {
      page-break-after: avoid;
    }
  }



  /*! CSS Used from: http://localhost:3000/css/bootstrap.min.css */
  *,::after,::before{box-sizing:border-box;}
  textarea{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
  textarea{overflow:auto;resize:vertical;}
  .form-control{display:block;width:100%;height:calc(2.25rem + 2px);padding:.375rem .75rem;font-size:1rem;font-weight:400;line-height:1.5;color:#495057;background-color:#fff;background-clip:padding-box;border:1px solid #ced4da;border-radius:.25rem;transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
  @media screen and (prefers-reduced-motion:reduce){
  .form-control{transition:none;}
  }
  .form-control::-ms-expand{background-color:transparent;border:0;}
  .form-control:focus{color:#495057;background-color:#fff;border-color:#80bdff;outline:0;box-shadow:0 0 0 .2rem rgba(0,123,255,.25);}
  .form-control::-webkit-input-placeholder{color:#6c757d;opacity:1;}
  .form-control::-moz-placeholder{color:#6c757d;opacity:1;}
  .form-control:-ms-input-placeholder{color:#6c757d;opacity:1;}
  .form-control::-ms-input-placeholder{color:#6c757d;opacity:1;}
  .form-control::placeholder{color:#6c757d;opacity:1;}
  .form-control:disabled{background-color:#e9ecef;opacity:1;}
  textarea.form-control{height:auto;}
  @media print{
  *,::after,::before{text-shadow:none!important;box-shadow:none!important;}
  }
  /*! CSS Used from: http://localhost:3000/css/bootstrap.min.css */
  *,::after,::before{box-sizing:border-box;}
  input{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}

  .limecall-close-btn-mobile{
    display: none;
    position: absolute;
    bottom: 0 !important;
    text-align: center;
    width: 100%;
    border-top: 1px solid gray;
    padding-top: 2%;
  }

  //@at-root
  @media only screen and (max-width: 410px) {
    .limecall-title{
      font-size: 26px !important;
    }
  }
  // @media only screen and (max-width: 768px) {
  //   .intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=tel], .intl-tel-input.allow-dropdown input[type=text]{
  //     font-size: 1rem !important;
  //   }
  // }
  @media only screen and (max-width: 767px) {
    /* For mobile phones: */
    .company_logo_header{
      max-height: 40px !important;
      margin-bottom: 30px !important;
    }
    .limecall-modal-dialog.limecall-widget.lime-call-popup {
      width: 100vw;
      height: 100%;
    }
    .hiddenVisibility{
      visibility: hidden !important;
    }
    .form-group {
      margin-bottom: 0.6rem !important;
    }

    .tel-input {
      input,select {
        height: 45px !important;
      }
    }
    .float-container, .textarea-float-container {
      label{
        transform: translate(0, 15px) scale(1) !important;
      }
    }
    .call_now__top{
      margin-bottom: 10px;
    }
    .float-container, .textarea-float-container{
      margin-bottom: 0.6rem !important;
    }
    .button-group {
      button{
        height: 45px !important;
      }
    }
    .nav {
      .nav-item {
        .nav-link{
          padding: 10px !important;
          padding-bottom: 7px !important;
        }
      }
    }
    .poweredby-container{
      padding: 7px 0 3px 0 !important;
    }    
    .lime-call-popup .limecall-modal-content {
        width: inherit;
        height: 100%;
    }
    .lime-call-popup .limecall-modal-body {
      padding-top: auto;
    }
    a.call_widget__copyright {
      margin-left: -10%;
    }
    .limecall-desktop-close{
      // display:none;
    }
    .limecall-close-btn-mobile{
      // display: block;
      bottom: -8px !important;
    }
    .slidemodalClose{
      display: block !important;
    }
    .poweredby-container{
      position: absolute;
      // bottom: 8px !important;
      bottom: 0;
      width: 100%;
    }
    .tab-content{
      min-height: 100% !important;
      margin-bottom: -40px;
      padding-bottom: 40px !important;
    }
    @supports not (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */ 
      .formHolder{
        // min-height: 50vh !important;
        max-height: 55vh !important;
      }
      .limecall-title {
        font-size: 26px !important;
      }
    }
    
    .nav-tabs{
      padding-top: 6px;
      z-index: 1200 !important;
    }
    .nav-tabs > li > a {
      font-size: 10.3px !important;
    }
    .tab-content > .tab-pane {
      position: absolute !important;
      top: 50% !important;
      -ms-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important;
      width: 100%;
    }
    .call_widget__copyright{
      position: relative;
      bottom: unset;
    }
    .limecall-widget.popup-button{
      right: 30px !important;
    }
  }

  select.feedbackInput-select {
    color: #6c757d;
    padding: 4%;
    width: 100%;
    border: 1px solid #e0e0e0;
    background: white;
}
input.feedbackInput-checkbox {
  padding: 7%;
  width: 5%;
  margin-left: 10px;
  height: unset;
}

label.feedbackInput-checkbox-label {
  margin: auto;
  margin-left: 2%;
  margin-right: 2%;
  float: inherit;
}

.tel-input.position-relative.form-group.limecall-checkbox-conttainer {
  display: flex;
}

textarea.feedbackInput-textarea {
  width: 100%;
  height: 100px;
}

//feedback form
.formHolder {
  max-height: 32vh;
  overflow-y: auto;
}

/* width */
.formHolder.feedback::-webkit-scrollbar {
  width: 20px;
  display: none;
}
#limecall-terms-lines{
  max-height: 35vh;
  overflow-y: auto;
}

#limecall-terms-lines::-webkit-scrollbar {
  width: 20px;
  display: none;
}

.lime-label-required{
  vertical-align: top !important;
  color:red !important;
  font-weight: 600 !important;
}

.requiredField{
  width: 97.5% !important;
  display: inline !important;
}
.lime-label-normal{
  font-size: 0.875rem;
  font-weight: normal;
  color: rgb(62, 62, 65) !important;
}
.tel-input > p{
  margin-bottom: 0.25rem !important;
}
.feedbackInput-textarea , input[type=datetime-local], input[type=text-box],input[type=email]{
  border-radius: 0.25rem;
}
.feedbackInput-textarea{
  border-color: #ced4da;
}
.float-container, .textarea-float-container {
  border: solid 1px #ccc;
  border-radius:0.25rem;
  box-sizing: border-box;
  margin-bottom: 0.4rem;
  padding: 0 10px;
  position: relative;
  width: inherit;
  
  input ,textarea{
    border: none;
    font-size: 13px;
    outline: 0;
    padding: 13px 0 4px;    
    width: 100%;
  }

  label {
    font-size: 14px;
    position: absolute;
    color:#524d4d;
    transform-origin: top left;
    transform: translate(0, 10px) scale(1); 
    transition: all .1s ease-in-out;  //speed
  }
  
  /** active label */
  &.active {
    label {
      //move the x coordinate and reduce size
      transform: translate(0, 4px) scale(.75) !important;
    }
  }
}

.float-containerSmart, .textarea-float-containerSmart {
  border: solid 1px #ccc;
  border-radius:0.25rem;
  box-sizing: border-box;
  margin-bottom: 0.6rem;
  padding: 0 10px;
  position: relative;
  width: inherit;
  
  input ,textarea{
    border: none;
    font-size: 13px;
    outline: 0;
    padding: 13px 0 4px;    
    width: 100%;
    height: 35px;
  }

  label {
    font-size: 14px;
    position: absolute;
    color:#524d4d;
    transform-origin: top left;
    transform: translate(0, 10px) scale(1); 
    transition: all .1s ease-in-out;  //speed
  }
  
  /** active label */
  &.active {
    label {
      //move the x coordinate and reduce size
      transform: translate(0, 4px) scale(.75);
    }
  }
}

.customCheckboxContainer{
  margin-bottom: 1rem;
  margin-left: 0.4rem;
  p{
    margin-bottom: 0.5rem !important;
  }
  .tel-input{
    margin-bottom: 0.4rem !important;
  }
}


.formHolder::-webkit-scrollbar {
  width: 5px;
}
.formHolder::-webkit-scrollbar-track {
  background: #cccccc; 
  border-radius: 5px;
}
.formHolder::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgb(72, 58, 46);
}
.terms-apply > a{
  color: #828181 !important;
  text-decoration: underline;
}
.limecall-modal-content > .tab-content,.limecall-modal-content > .nav-tabs>li.active>a {
  // background-color: #fff !important;
}
.limecall-modal-content label{
  font-weight: unset !important;
}
.smart-button-wide{
  width:200px !important;
  border-radius: 2rem !important;
  cursor: default !important;
}
.button-connecting{
  background-image: linear-gradient(to right, #37ea0a, #ea8c2a) !important;
}
.button-connected{
  background: #4CD964 !important;
}
span.smart-button-status {
  color: #fff;
  display: inline-grid;
  width: 150px;
  text-align: center;
  vertical-align: super;
}
.smart-call-cut{
  display: inline-grid !important;
  margin-top: 8px;
  line-height: 24px !important;
  padding:9px;
  border-radius: 50%;
  background-color: red;
  cursor: pointer;
}
.lime-smart-bubbleslide{
  position: absolute;
  background: #fff !important;
  z-index: 100 !important;
  min-height: 150px;
  width: 228px;
  box-shadow: 0 5px 20px 0 rgba(0,0,0,0.1);
  display: none;
}
.lime-smart-bubbleslide-top{
  top:70px;
}
.lime-smart-bubbleslide-right{
  right: 0;
}
.lime-smart-bubbleslide-bottom{
  bottom:70px;
}
.lime-smartslide-open{
  display: block !important;
}
.smartslide-close-btn{
  position: absolute;
  top: 5px;
  right: 2px;
  width: 15px;
  height: 15px;
  opacity: 1;
  z-index: 100;
  cursor: pointer;
}
.smart-call-ended{
  background: #f83938 !important;
  line-height: 60px;
}
.fullsize-smart-statusbox{
  width: 200px !important;
}
.feedbackSlide{
  margin: 10px;
  padding-top: 10px;
  font-size: 13px;
  text-align: center;
  max-height: 65vh;
  overflow-y: scroll;
}
.hdSlidesmall{
  min-height: 100px !important;
  max-height: 70vh;
}
.hdSlidesmall70{
  min-height: 70px !important;
  max-height: 70vh;
}
.smartEmoji-container svg{
  width: 24px !important;
  height: 24px !important;
  margin: 3px;
  margin-top: 10px;
  cursor: pointer;
}

.smiley-container > svg{
  width: 16px !important;
  height: 16px !important;
  vertical-align: text-bottom;
}
.smart-timer-lime{
  position: absolute;
  left: 30px;
  top: 10px;
  font-size: 20px;
  color: white;
  font-family: Arial;
  font-weight: 600;
}
.smart-connected-limeStat{
  vertical-align: unset !important;
  font-size: 13px;
}
.smart-callConnectedStat{
  position: absolute;
  left: 30px;
  top: 35px;
}
.errorSlide{
  border-color: #F44336 !important;
}
.call-failed-schedule-container{
  margin-top: 18px;
  margin-bottom: 8px;
}
.smartwidget-button-fullsize{
  line-height: 60px;
}
.schedule-icon-smartwid{
  font-size: 18px;
  vertical-align: bottom;
}
.call-schedulebutton-limeSmart{
  padding: 11px;
  font-size: 14px;
  border: 1px solid #16299c;
  border-radius: 5rem;
  background-color: #16299c;
  color: #fff;
  cursor: pointer;
}
.call-schedulebutton-limeSmart:hover{
  padding:13px;
  color: #fff;
}
.call-failed-retry-container{
  font-size: 13px;
  margin-top: 23px;
}
.call-retrybutton-limeSmart{
  padding: 5px 6px;
  cursor: pointer;
  border-radius: 2rem;
}
.call-retrybutton-limeSmart:hover{
  border: 1px solid;
}
.smartwid-team-container{
  margin: 10px;
  margin-bottom: 0;
}
.smartwid-team-selectorbutton{
  width: 100%;
  padding: 5px;
  border: 1px solid;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px !important;
}
.smartwid-team-selectorbutton:hover{
  background-color: #e6e6e6;
}
.smartwidget-phonenumber-container{
  margin: 5px;
  margin-top: 10px;
}
.smartwidget-phonenumber-container > .intl-tel-input.allow-dropdown input, .smartwidget-phonenumber-container > .intl-tel-input.allow-dropdown input[type=tel], .smartwidget-phonenumber-container > .intl-tel-input.allow-dropdown input[type=text]{
  padding-left: 65px !important;
  width: 100%;
  font-size: 0.8rem;
  min-height: 30px;
}
.smartwidget-phonenumber-container > .intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code{
  // padding-left: 15px;
}
.smartschedule-btn-container{
  padding: 8px 18px;
  border: 1px solid #16299c;
  font-size: 14px;
  border-radius: 2rem;
  cursor: pointer;
  color: #fff;
  background-color: #16299c;
}
.limecall-modal-content > .tab-content{
  margin: unset !important;
  border: unset !important;
  padding:unset !important;
  min-height: 50vh !important;
  max-height: 50vh !important;
}
.limecall-modal-content > .nav> .nav-item{
  padding:unset !important;
}
.textarea-float-container > label, .float-container > label{
  margin:unset !important;
}
.poweredby-icon{
  vertical-align: top;
  border-style: none;
  height: 15px;
  margin-right: 6px;
}
.poweredby-container{
  padding: 4px 0 0 0;
  text-align: center;
  font-family: Helvetica, arial, sans-serif;
  border-top: 1px solid #dee2e6;
  color: rgb(119, 118, 120) !important;
  background: #fff;
}
.poweredby-section{
  padding: 4px 10px;
  color: #454545;
  font-size: 11px;
  text-decoration: none;
  color: rgb(119, 118, 120) !important;
}
.poweredby-section:hover{
  border: 1px solid #f1f1f1;
  border-radius: 2rem;
  // color: rgb(119, 118, 120) !important;
  text-decoration: none;
  background-color: #f1f1f1;
  color: #5f5e61 !important;
}
.lime-smartslide-open  .poweredby-container {
  padding: 1px 0 4px 0 !important;
}
.lime-smartslide-open .poweredby-section {
  font-size: 10px !important;
}
.lime-smartslide-open .poweredby-icon {
  vertical-align: middle !important;
  height: 12px !important;
}
.smartEmojiSign{
  position: relative;
  display: inline-block;
}
.smartEmojiSign .smartEmojiSign-tooltip{
  visibility: hidden;
  // width: 70px;
  font-size: 10px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  top: 95%;
  left: 50%;
  margin-left: -30px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  transition: opacity .6s;
}
.smartEmojiSign:hover .smartEmojiSign-tooltip{
  visibility: visible; 
}
.smartEmojiSign:hover svg{
  width: 25px !important;
  height: 25px !important;
  transition: opacity .6s;
}
.button-group > button{
  cursor: pointer;
}
.css-26l3qy-menu, .schedule-call-select-mod__menu,.schedule-call-select-mod-smart__menu{
  z-index: 1000 !important;
}
.scheduledate-selector{
  margin-right: 4px;
}
.schedule-call-select-mod-smart {
  width: 100%;
  min-height: 40px;
  text-align: left;
  background-color: $snow-white;
  margin-bottom: 10px;
}
.schedule-call-select-mod-smart__value-container{
  padding: 2px 2px !important;
}
.schedule-call-select-mod-smart__indicator{
  padding:0px !important;
}
.schedule-call-select-mod-smart__control{
  min-height: 32px !important;
  font-size: 0.7rem !important;
}
.schedule-call-select-mod-smart__menu ,  .css-2b097c-container{
  font-size: 0.7rem !important;
}
.feedbackSlide .css-26l3qy-menu{
  font-size: 0.7rem !important;
}
@media only screen and (min-width: 767px) {
  .slidetemplate {
    top: unset !important;
    bottom: unset !important;
    right: unset !important;
    left: unset !important;
    width: unset !important;
    position: fixed !important;
    height: unset !important;
    background: unset !important;
  }

  .slideModal{
    border-radius: .3rem;
    margin-left: 3px;
    margin-right: 3px;
    box-shadow: rgba(0,0,0,.1) 0 0 3px 2px;
  }

  .topRight{
    top: 87px!important;
    right: 37px!important;
  }

  .topLeft{
    top: 87px !important;
    left: 37px !important;
  }

  .bottomRight{
    bottom: 87px !important;
    right:37px !important;
  }

  .bottomLeft{
    bottom: 87px !important;
    left: 37px !important;
  }
}
@media only screen and (min-width:541px){
  .limemodernTemplate.bottomLeft{
    bottom: 120px !important;
    left: 37px !important;
  }
  .limemodernTemplate.topRight{
    top: 120px!important;
    right: 37px!important;
  }

  .limemodernTemplate.topLeft{
    top: 120px !important;
    left: 37px !important;
  }

  .limemodernTemplate.bottomRight{
    bottom: 120px !important;
    right:37px !important;
  }
}
.schedule-call-select-mod__menu{
 z-index: 1200;
}
.overflowed-y {
  overflow-y: visible !important;
}
.shareableWid{
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
}


@media (min-width: 576px) {
  .shareableWid .formHolder{
    max-height: unset !important;
  }
  .team-dropdown-select-mod__control , .team-dropdown-select-mod__value-container , .schedule-call-select-mod__control , .schedule-call-select-mod__value-container , .css-yk16xz-control , .css-1pahdxg-control , .css-g1d714-ValueContainer{
    min-height: 33px !important;
    height: 33px !important;
  }
}

.errorContainer{
  border: solid 1px red !important;
}

.teamErrorContainer .team-dropdown-select-mod__control{
  border: solid 1px red !important;
}

.intlErrorContainer .intl-tel-input{
  border: solid 1px red !important;
}
 .limecall-modal-content > .nav-tabs > li > a{
  background: unset !important;
  border: unset !important;
 }
 .limecall-modal-content span{
  font-family : inherit !important;
  font-size: inherit !important;
 }

.feedbackSlide .team-dropdown-select-mod__control ,.feedbackSlide .team-dropdown-select-mod__value-container{
  min-height: 33px !important;
  height: 33px !important;
}
.dv-star-rating-empty-star{
  color:#c0c0c0c9 !important;
}
.dv-star-rating-empty-star:hover{
  color: rgb(255, 180, 0) !important;
}
.email-container input, .name-container input ,.company-name-container input, .custom-textinput-container input{
  padding-left: 10px;
  border:solid 1px #ccc;
  border-radius: 0.25rem;
}

.email-container, .name-container ,.company-name-container, .custom-textinput-container{
  margin-bottom: 0.4rem;
}

.smartButtonMainCTA{
  position: absolute;
  left: 53px;
  top: 12px;
  font-size: 16px;
  color: white;
  font-family: Arial;
  font-weight: 600;
}
.smartButtonMainCTAsecondary{
  position: absolute;
  display: block;
  top: 32px;
  font-size: 12px !important;
  width: unset !important;
  margin-right: 10px;
  line-height: 11px;
  left: 53px;
}

li.country {
  font-size: 13px !important;
}
.intl-tel-input .country-list .flag-box, .intl-tel-input .selected-flag .iti-flag {
  display: none;
}
.selected-dial-code{
  padding-left: unset !important;
}
.selected-flag{
  width: 48px !important;
}
.email-container input, .name-container input{
  padding-left: 43px;
}
.email-container input:hover, .name-container input:hover{
  border: solid 1px #000;
  background-color: #f8f8f8;
}
.custom-icon-input{
  margin-right: -33px;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 15px;
  font-size: 18px;
  z-index: 100;
}
.email-container, .name-container{
  display: flex;
}
button:hover{
  filter: brightness(85%);
  color: white !important;
  border: solid 1px #000 !important;
}
.limemodernPopupbottom_left{
  display:flex;
  bottom: 37px;
  left:37px;
}
.limemodernPopupbottom_right{
  display:flex;
  bottom:37px;
  right:37px;
}
.limemodernPopuptop_right{
  display:flex;
  top:37px;
  right:37px;
}
.limemodernPopuptop_left{
  display:flex;
  top:37px;
  left:37px;
}
.limemodernTemplate{
  position: fixed;
  z-index: 2147483646;
  width: 275px;
  min-height: 240px;
  border-radius: 8px;
  box-shadow: 0 0 3px 3px rgba(0,0,0,.1);
}
.limemodernTempHeader{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 150px;
  padding:18px;
  background-image: linear-gradient(to left, #0174ff,rgba(0, 197, 255, 1));
}
.limemodernPoweredSection{
  font-size: 10px;
  font-weight: 100;
  bottom: 10px;
  font-family: sans-serif;
  position: absolute;
  width: 100%;
  text-align: center;
  margin-left: -10px;
}
.limemodernTempBody{
  padding:10px;
  background: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.limemodernCompanyLogo{
  max-height: 30px;
  max-width: -webkit-fill-available;
}
.limemodernGreetingHeader{
  color: #fff;
  padding-top: 8px;
  font-family: sans-serif;
}
.limemodernGreetingHeader h3{
  font-size: 18px;
  margin-bottom: 0.3rem;
  font-weight: 300;
}
.limemodernGreetingBody{
  color: #fff;
  font-size: 12px;
  font-weight: 200;
  font-family: sans-serif;
}
.limemodernBodyContainer{
  margin-bottom: 30px;
  margin-top: -45px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: sans-serif;
}
.limemodernSelectorButton{
  background-color: #fff;
  box-shadow: 0 0 3px 2px rgba(0,0,0,.1);
  cursor: pointer;
  text-align: center;
  align-items: center;
  font-size: 13px;
  border-radius: 5px;
  font-weight: 100;
  padding: 30px;
  margin-bottom: 18px;
}
.limemodernSelectorIcons{
  vertical-align: middle;
  font-size: 21px;
  padding-right: 4px;
}
.limemodernFormContainer{
  text-align: center;
  align-content: center;
  box-shadow: 0 0 3px 2px rgba(0,0,0,.1);
  background-color: #fff;
  border-radius: 5px;
  color: #908c8c;
  font-weight: 100;
  font-size: 12px;
  padding: 24px 12px 12px;
}
.limemodernFormContainer input,.limemodernFormContainer select{
  height: 33px;
  max-width: fit-content;
}
.limemodernFormContainer .selected-dial-code{
  color: black;
}
.limemodernFormContainer input{
  max-width: 195px;
  width: 195px;
}
.intl-tel-input.allow-dropdown input[type=tel]{
  padding-left: 60px !important;
}
.limemodernSubmitButton{
  width: 195px;
  border: none;
  height: 38px;
  background-color: #0273ff;
  color:#fff;
  font-size: 13px;
  cursor: pointer;
  filter:none !important;
  font-weight: 100;
}
.modernCallIconMicOff{
  color:#b3b3b3;
  margin-right: 13px;
}
.limemodernSecondaryText{
  font-size: 9px;
  padding: 2px;
}
.limemodernFormContainer p{
  margin-bottom: 0;
}
.limemodernTeamSelection {
  background-color: #fff;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.limemodernTeamSelection ul{
  list-style-type: none;
  padding-inline-start: 0px;
  padding-top: 10px;
}
.limemodernTeamSelection ul li{
  cursor: pointer;
  text-align: center;
  padding-top: 13px;
  padding-bottom: 13px;
  font-size: 12px;
  border-bottom: 1px solid #ededed;
}
.limemodernTeamSelection ul li:hover{
  background-color: #ededede0;
}
.limemodernRingingCall{
  background-color: #fff;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  align-content: center;
  text-align: center;
}
.limemodernConnectedCall{
  background-color: #fff;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  align-content: center;
  text-align: center;
}
.limemodernAgentImage{
  width: 30px;
  height: 30px;
  border-radius: 3px;
  margin-right: 8px;
}
.limemodernAgentDetails{
  padding: 10px;
  font-family: sans-serif;
  font-weight: 100;
  font-size: 14px;
  display: flex;
}
.limeModernCallConnectedActionsSection{
  color: #fff;
  background-color: #23b573;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  width: 100%;
}
.callConnSubText{
  font-weight: 100;
  font-size:9px;
  font-family: sans-serif;
  padding-top: 2px;
  letter-spacing: 0.5px;
}
.callConnSecCounter{
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 1px;
}
.callConnActionButonContainer{
  width: 50%;
}
.callConnCounterContainer{
  width: 50%;
}
.moderncallActions{
  background-color: #fff;
  border-radius: 30px;
  color: #000;
  padding: 6px;
  font-size: 18px;
  cursor: pointer;
}
.limeModernCallEndedActionsSection{
  color: #fff;
  background-color: #f90c56;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
}
.limemodernAgentNameBox{
  margin-right: 5px;
  padding: 8px 0px;
}
.limemodernAgentJobDesignation{
  font-size: 10px;
  color: rgb(153, 153, 153);
  padding: 8px 0px;
}
.callEndTextSec{
  font-weight: 300;
  font-size:14px;
}
.modernEmojiObject{
  padding-right: 10px;
}
.limemodernFailedHeader{
  background-image: linear-gradient(to right, #f12b11, #f5ae1a) !important;
}
.limemodernDateTimeContainer{
  background-color: #fff;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.limemodernDateToggleContainer{
  font-size: 10px;
  display: flex;
  padding: 11px 6px;
  background-color: #f4f4f4;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.limemodernDateToggle{
  width: 50%;
  cursor: pointer;
}
.limemodernDateToggleRight{
  text-align: right;
}
.dateToggleIcon{
  vertical-align: middle;
  font-size: 18px;
}
.limemodernTimeContainer{
  padding-bottom: 10px;
  max-height: 150px;
  overflow: auto;
}
.limemodernTimeRow{
  display: flex;
}
.limemodernTimeObject{
  width: 50%;
  text-align: center;
  margin: 10px;
  font-size: 12px;
  padding:10px;
  margin-bottom: 3px;
  cursor: pointer;
  color: #91929f;
  font-weight: 300;
}
.limemodernSelectedTime ,.limemodernTimeObject:hover{
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  color: #1f87ff;
  padding:9px;
  font-weight: 500;
}
.limemodernDateTimeSelectedContainer{
  background-color: #f4f4f4;
  margin-top: 15px;
  border-radius: 5px;
}
.limemodernDateTimerow{
  display: flex;
  padding: 10px 0;
}
.limemodernDateTimeIconContainer{
  padding: 0 12px;
}
.limemodernSelectedDateDisplay{
  font-size: 10px;
  color: #808080;
  padding-top: 3px;
  font-weight: 200;
}
.limemodernSelectedTimeDisplay{
  color: #4d4d4d;
  font-size: 13px;
}
.modernNextBtnIcon{
  vertical-align: middle;
  margin-left: -3px;
}
.limemodernNextBtn{
  font-size: 12px;
  color: #2d90e7;
  cursor: pointer;
  padding:6px
}
.limemodernNextBtnContainer{
  text-align: center;
  padding: 10px 0 0 0;
}
.limemodernAdditionalDetailsContainer{
  background-color: #fff;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px;
}
.limecallmodernFormContainer{

}
.limemodernBackButton{
  text-align: right;
  font-size: 11px;
  color:#999999;
}
.limemodernFormScheduleContainer{
  box-shadow: none;
  padding: 10px 0;
}
.limemodernScheduleSubmitContainer{
  padding: 15px;
  text-align: center;
  padding-bottom: 0;
}
.limemodernConfirmationContainer{
  background-color: #fff;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px;
  color: #4d4d4d;
  font-size: 12px;
  text-align: center;
}
.limemodernDoneSign{
  font-weight: 600;
  color: #23b573;
  padding: 12px;
  background-color: #e7f9f5;
  border-radius: 50%;
  margin: 12px 0;
}
.limemodernFormContainer input::-webkit-input-placeholder {
  text-align: start;
  font-weight: 13px;
}
.limemodernFormContainer input::-moz-placeholder {
  text-align: start;
  font-weight: 13px;
}
.limemodernFormContainer input{
  padding-left: 10px;
}
.limemodernAgentInfoContainer{
  display: flex;
}
.inputFieldContainerModern input{
  border: 0.3px solid #c4c4c4;
  border-radius: 3px;
}
.limemodernButtonRow{
  width: 100%;
  text-align: center;
}
.limemodernSubmitButtonSmall{
  border: none;
  background-color: #0273ff;
  color: #fff;
  height: 34px;
  font-size: 12px;
  cursor: pointer;
  filter:none !important;
  font-weight: 400;
  width: 90px;
}
.inputFieldContainerModern > .intl-tel-input.allow-dropdown .selected-flag{
  background: none !important;
  border-right: 0.3px solid #c4c4c4;
  padding:0;
}
.modernErrorField{
  border-color: #FF5722 !important;
}
.limemodernErrorMessage{
  font-size: 9px;
  text-align: center;
  color: #FF5722;
  margin-top: 6px;
}
.limemodernBackButton > span:hover {
  color: #363636;
}
.limemodernFormContainer .intlErrorContainer .intl-tel-input{
  border: none !important;
}
.intlErrorContainer input{
  border-color: #FF5722 !important;
}

@media only screen and (max-width:540px){
  .limemodernTemplate{
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    left: 0;
    top: 0;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    background: #fff;
    height: 100%;
  }
  .limemodernTempHeader{
    height: 35%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: #0174ff;
    background-image: none;
    padding: 7%;
  }
  .limemodernPoweredSection{
    text-align: right;
    right: 20px;
    bottom: 15px;
  }
  .limemodernWidget.popup-button{
    z-index: 2147483647 !important;
  }
  @media (max-width: 541px) {
    .limemodernWidget.popup-button.limeModalOpenWid{
      bottom: 27px !important;
      left:27px !important;
    }
  }
  .limemodernWidget .twilio-wd-open-widget-popup{
    width: 55px;
    height: 55px;
    line-height: 55px;
  }
  .limemodernWidget .twilio-wd-open-widget-popup i{
    font-size: 28px;
  }
  .limemodernPopupbottom_left{
    bottom: 27px;
    left:27px;
  }
  .limemodernPopupbottom_right{
    bottom:27px;
    right:27px;
  }
  .limemodernPopuptop_right{
    top:27px;
    right:27px;
  }
  .limemodernPopuptop_left{
    top:27px;
    left:27px;
  }
  .limemodernSelectorButton{
    padding: 18%;
    margin-bottom: 25px;
    font-weight: 500;
  }
  .limemodernBodyContainer{
    margin-bottom: 30px;
    margin-top: -60px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .limemodernLogoContainer{
    padding: 8px 0;
  }
  .limemodernGreetingHeader h3{
    padding: 15px 0px 5px 0px;
  }
  .limemodernRingingCall img{
    width: 200px !important;
    padding: 10% 0;
  }
  .limemodernFormContainer .intl-tel-input input{
    max-width: 100%;
    width: 100%;
  }
  .limemodernFormContainer input{
    max-width: 100%;
    width: 100%;
  }
  .limemodernSubmitButton{
    width: 80%;
  }
  .inputFieldContainerModern{
    padding: 0 10%;
  }
  .inputFieldContainerModern .intl-tel-input{
    width: 100%;
  }
  .limemodernAgentDetails{
    padding: 10% 12%;
  }
  .limemodernAgentNameBox{
    padding-bottom: 0;
  }
  .limemodernAgentJobDesignation{
    text-align: left;
    padding-top: 4px;
  }
  .limemodernAgentImage{
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
  .limemodernAgentInfoContainer{
    display: block;
  }
  .limeModernCallConnectedActionsSection{
    display: block;
  }
  .callConnCounterContainer{
    width: 100%;
  }
  .callConnActionButonContainer{
    width: 100%;
    padding-top: 15px;
  }
  .moderncallActions{
    padding: 12px;
    font-size: 24px;
  }
  .modernCallIconMicOff{
    margin-right: 25%;
  }
}
.limecall-widgetSideBubble{
  display: block;
  width: fit-content;
  padding: 9px 18px;
  text-align: center;
  top: 35%;
  position: fixed;
  box-shadow: rgb(50, 50, 50 / 75%) 0px 0px 10px 0px;
  border-radius: 6px 6px 0px 0px;
  color: #fff;
  cursor: pointer;
}
.middleRightBubble{
  transform: rotate(270deg);
  transform-origin: right top;
  margin-right: 35px;
  right: 0;
}
.middleLeftBubble{
  transform: rotate(90deg);
  transform-origin: left top;
  margin-left: 35px;
  left: 0;
}
@media (min-width: 767px) {
  .middleLeft{
    top: 87px !important;
    left: -5px  !important;
  }
  .middleRight{
    top: 87px !important;
    right: -5px  !important;
  }
}
.material-icons{
  font-family: "Material Icons" !important;
}
.limeIntercomTrigger{
  display: none;
}
.limeModernIntercom{
  bottom: 0 !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
}
.limeModernIntercom .limemodernTempHeader{
  border-top-left-radius:0;
  border-top-right-radius:0;
}